import React, { Suspense, lazy, useEffect, useState } from 'react';
import { HashRouter, Route } from 'react-router-dom';

import styled from 'styled-components';
import Axios from 'axios';

import queryString from 'query-string';

import { analyticsService } from 'src/store/analytics';
import { appService, appQuery } from 'src/store/app';

import { IExperienceData, ExperienceData } from 'src/experiences';
import LoadingScreen from 'src/shared/loadingScreen';

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
`;

analyticsService.init();
const Home = lazy(() => import('../Home'));
const Experience = lazy(() => import('../Experience'));

const getExperiencesPath = () => {
  if (window.location.search) {
    const query = window.location.search;
    const queryObject = queryString.parse(query);
    if (queryObject.customJsonPath) {
      return queryObject.customJsonPath as string;
    }
  }
  return '/api/release-v1.json';
};

const View = () => {
  const [experiences, setExperiences] = useState<ExperienceData[] | null>(null);
  const [customPathError, setCustomPathError] = useState<boolean>(false);

  useEffect(() => {
    // '/api/pottery_barn.json', // locked by cors -> 'https://ryot-ar-sdk.s3-us-west-1.amazonaws.com/dev/ios/pottery_barn/pottery_barn.json',
    // '/api/face-filter.json', // locked by cors -> 'https://ryot-ar-sdk.s3-us-west-1.amazonaws.com/dev/ios/faceFilters/face_filter.json',
    // '/api/stonewall.json', // locked by cors -> 'https://ryot-ar-sdk.s3-us-west-1.amazonaws.com/dev/ios/stonewall_110/stonewall.json',
    // '/api/release-v1.json', // all the previous ones in one file

    const subscription = appQuery.getExperiences$.subscribe(storeExperiences => {
      setExperiences(storeExperiences);
    });

    const experiencesPath = getExperiencesPath();
    Axios.get(experiencesPath)
      .then(data => {
        const experiencesData = data.data as IExperienceData[];
        const allExperiences = experiencesData.map(experience => new ExperienceData(experience));
        appService.setExperiences(allExperiences);
      })
      .catch(ex => {
        setCustomPathError(true);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  if (customPathError) {
    return <div>TODO page: {window.location.search} is not a valid experience URL.</div>;
  }

  if (experiences === null || experiences.length === 0) {
    return <LoadingScreen />;
  }

  console.log('I will pass in the experiences', experiences);

  return (
    <Container>
      <Suspense fallback={<LoadingScreen />}>
        <HashRouter>
          <Route path="/" render={() => <Home />} exact />
          <Route path="/experience/:id" render={() => <Experience />} />
        </HashRouter>
      </Suspense>
    </Container>
  );
};

export default View;
