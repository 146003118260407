import styled, { createGlobalStyle, DefaultTheme } from 'styled-components';

export const TRANSITION_TIME = '0.3s';
export const NOTIFICATION_DURATION = '1s';

export const ACTIVE_COLOR = '#007AFF';

export const theme: DefaultTheme = {
  fontsFamily: {
    text: '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu',
    headline: '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu',
  },

  colors: {
    text: '#000000',
    activeColor: ACTIVE_COLOR,
  },
};

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-family: ${theme.fontsFamily.text};
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }

  body {
    margin: 0;
    font-family: ${theme.fontsFamily.text};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
  }
`;

export const Button = styled.button`
  color: #fff;
  background: ${theme.colors.activeColor};
  border-radius: 30px;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.5em 30px;
`;

export const FullScreenContainer = styled.div<{ disableContainerPointerEvents?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  ${({ disableContainerPointerEvents }) => {
    if (disableContainerPointerEvents) {
      return `
      pointer-events: none;
      * {
        pointer-events: auto
      }
  `;
    }
    return null;
  }}
`;

export const FullScreenThemedContainer = styled(FullScreenContainer)`
  background: url(/img/theme/loading-background.png);
  background-size: cover;
`;

export const ThemedIcon = styled.div`
  path {
    fill: ${ACTIVE_COLOR};
  }
`;

export const DialogContainerDiv = styled.div<{ show: boolean }>`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: rgba(74, 74, 74, 0.427);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
  ${({ show }) =>
    show &&
    `
    opacity: 1;
    visibility: visible;
  `}

  .dialog-div {
    padding: 30px 15px 0px 15px;
    background: white;
    width: calc(100% - 50px);
    border-radius: 10px;
    box-sizing: border-box;
    text-align: center;

    .dialog-title-div {
      font-size: 19px;
      font-weight: bold;
    }
    .dialog-description-div {
      color: gray;
      padding: 10px 0px 10px 0px;
    }
    .textfield-div {
      width: 100%;
      margin: 0px 0px 10px 0px;
    }
    .add-item-button {
      width: 100%;
      height: 50px;
      margin-bottom: 10px;
    }
    .cancel-button {
      color: gray;
      width: 100%;
      height: 50px;
      margin-bottom: 10px;
    }
  }
`;
