import React from 'react';
import styled from 'styled-components';
import { FullScreenContainer } from 'src/shared/theme/styled';

const Container = styled(FullScreenContainer)`
  z-index: 10;
  // background: url(/img/theme/loading-background.png);
  background-color: #000;
  pointer-events: auto;
`;

const LoadImage = styled.img`
  position: absolute;
  margin-top: -5em;
  margin-left: -5em;
  top: 50%;
  left: 50%;
  height: 10em;
  width: 10em;
  // transform: translate(-50%, -50%);
`;

const LoadingScreen = (props: { style?: React.CSSProperties }) => {
  return (
    <Container style={props.style}>
      <LoadImage src="/img/theme/loading-animation.gif" alt="" />
    </Container>
  );
};

export default LoadingScreen;
