import { Store, StoreConfig } from '@datorama/akita';
import { ExperienceData } from 'src/experiences';

export interface IAppState {
  experiences: ExperienceData[] | null;
  selectedExperience: string | null;
}

@StoreConfig({ name: 'App' })
export class AppStore extends Store<IAppState> {
  constructor() {
    super({
      // Add initial State
      experiences: null,
      selectedExperience: null,
    });
  }
}
export const appStore = new AppStore();
