import { AppContainer } from 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';

/**
 * Please include config before any of our content.
 * If will set up some global stuff which can be
 * accesed by component/classes at the time if require/import,
 *
 * TODO: all our components shoud be lazy-loaded,
 * so we don't need this race condition hack
 */
import './config';

import App from './App';
import { akitaDevtools } from '@datorama/akita';
import { ThemeProvider } from 'styled-components';
import { theme, GlobalStyle } from './shared/theme/styled';

akitaDevtools({ name: 'web-ar' });

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <App />
      </ThemeProvider>
    </AppContainer>,
    document.getElementById('root'),
  );
};
render();

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept('./App', () => {
    render();
  });
}
