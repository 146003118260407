const config = {
  development: {},
  now: {},
  production: {},
  common: {
    basename: '',
  },
};

const REACT_APP_STAGE = process.env.REACT_APP_STAGE || 'development';

if (process.env.NODE_ENV === 'development') {
  window.setFakeDevice = () => sessionStorage.setItem('fakeDevice', true);
  window.clearFakeDevice = () => sessionStorage.removeItem('fakeDevice');
}

window.isFakeDevice = () => sessionStorage.getItem('fakeDevice');

export default {
  ...config.common,
  ...config[REACT_APP_STAGE],
};
