// eslint-disable-next-line max-classes-per-file
import { Store, StoreConfig } from '@datorama/akita';
import { BeaconClickEvent, TRACKING_EVENT, AnalyticsManager, RapidAnalyticsService, DEVICE_TYPE } from 'tracking';
import { take } from 'rxjs/operators';
import device from 'current-device';
import { appQuery } from '../app';

// eslint-disable-next-line @typescript-eslint/camelcase
const defaultOptions = { sdk_version: '1.0' };

export interface IAnalyticsState {
  lastEvent: {
    type: TRACKING_EVENT;
    options?: any;
    selectedExperience: string | null;
  } | null;
}

@StoreConfig({ name: 'Analytics' })
export class AnalyticsStore extends Store<IAnalyticsState> {
  constructor() {
    super({
      // Add initial State
      lastEvent: null,
    });
  }
}
export const analyticsStore = new AnalyticsStore();

export class AnalyticsService {
  analyticsManager: AnalyticsManager = new AnalyticsManager();

  // It's not a useless constor. It's creating aprivate store property (https://github.com/eslint/typescript-eslint-parser/issues/546)
  // eslint-disable-next-line no-useless-constructor
  constructor(private store: AnalyticsStore) {}

  public track = (type: TRACKING_EVENT, options?: any) => {
    appQuery.getSelectedExperience$.pipe(take(1)).subscribe(selectedExperience => {
      const mergedOptions = { ...defaultOptions, ...(options || {}) };
      this.analyticsManager.track(new BeaconClickEvent('', '', -1, {}, type, mergedOptions, selectedExperience || ''));
      this.store.update({
        lastEvent: {
          type,
          options,
          selectedExperience,
        },
      });
    });
  };

  public init = () => {
    // Read device type
    let deviceType;
    if (device.type === 'mobile') {
      deviceType = DEVICE_TYPE.mobile;
    } else if (device.type === 'tablet') {
      deviceType = DEVICE_TYPE.tablet;
    } else {
      deviceType = DEVICE_TYPE.desktop;
    }
    this.analyticsManager.add(new RapidAnalyticsService(window.YAHOO, deviceType));
  };
}

export const analyticsService = new AnalyticsService(analyticsStore);
