import { AppStore, appStore } from './app-store';
import { ExperienceData } from 'src/experiences';

export class AppService {
  // It's not a useless constor. It's creating aprivate store property (https://github.com/eslint/typescript-eslint-parser/issues/546)
  // eslint-disable-next-line no-useless-constructor
  constructor(private store: AppStore) {}

  public setExperiences(experiences: ExperienceData[]) {
    this.store.update({
      experiences,
    });
  }

  public setSelectedExperience(selectedExperience: string | null) {
    this.store.update({
      selectedExperience,
    });
  }

  public getExperiences(): ExperienceData[] | null {
    return this.store.getValue().experiences;
  }
}

export const appService = new AppService(appStore);
