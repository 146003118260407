import { Query } from '@datorama/akita';
import { IAppState, AppStore, appStore } from './app-store';

export class AppQuery extends Query<IAppState> {
  public getExperiences$ = this.select(state => state.experiences);

  public getSelectedExperience$ = this.select(state => state.selectedExperience);

  constructor(protected store: AppStore) {
    super(store);
  }
}

export const appQuery = new AppQuery(appStore);
