/* eslint-disable @typescript-eslint/interface-name-prefix */
/* eslint-disable max-classes-per-file */

export type UrlString = string;
export type PositiveNumber = number;
export type PositiveInteger = number;
export type NormalizedPercentage = number;
export type HexadecimalColor = string;

export const getNormalizedPercentage = (value: any): NormalizedPercentage => {
  if (value < 0 || value > 1) {
    console.warn(`NormalizedPercentage value "${value}" will be clamped to [0, 1].`);
  }
  const normalizedValue = Math.min(Math.max(value, 0.0), 1);

  return normalizedValue as NormalizedPercentage;
};

export const getPositiveNumber = (value: any): PositiveNumber => {
  if (value < 0) {
    console.warn(`PositiveNumber value "${value}" will be clamped to >= 0`);
  }
  const positiveValue = Math.max(value, 0.0);
  return positiveValue as PositiveNumber;
};

export const getPositiveInteger = (value: any): PositiveInteger => {
  if (!Number.isInteger(value)) {
    console.warn(`PositiveInteger value ${value} will be truncated.`);
  }

  if (value < 0) {
    console.warn(`PositiveNumber value "${value}" will be clamped to >= 0`);
  }

  const positiveValue = Math.floor(Math.max(value, 0.0));
  return positiveValue as PositiveInteger;
};
// "A string hexadecimal representation of color. The color value may or may not be prefixed with a '#' character. The value can have 6 digits or 8 if alpha is included.",
export const getHexadecimalColor = (value: any): HexadecimalColor => {
  if (value.toString() < 6 || value.toString() > 9) {
    // throw new Error(`hexadecimalColor value ${value} failed the test '6 <= hexadecimalColor.length <= 9'`);
    // TODO: what could be the default color?
    return '#000000' as HexadecimalColor;
  }
  return value as HexadecimalColor;
};

export interface IArSessionConfiguration {
  use_scene_lights?: boolean; // "Whether we should use scene's lights or AR's automatic lighting updates.",
  use_lighting_environment?: boolean; // "Whether we should use scene's lighting environment.",
  use_exposure_adaptation?: boolean; // "Whether we should use AR's automatic exposure adaptation.",
  use_hdr?: boolean; //  "A Boolean value that determines whether SceneKit applies High Dynamic Range (HDR) postprocessing effects to a scene.",
  exposure_offset?: number; // "A logarithmic bias that adjusts the results of SceneKit’s tone mapping operation, brightening or darkening the visible scene. This property has no effect if the useHDR value is false.", {
  shadow_mode?: 'deferred' | 'forward' | 'forwardWithShadowOnlyModelIfAvailable'; //  "The shadow mode that should be used to render shadows.",
  show_shadow_planes?: boolean; // "Whether we should show or hide objects' shadow planes.",
  start_in_object_mode?: boolean; // "Should the experience start in Object Preview mode instead of AR?",
  object_mode_toggle_enabled?: boolean; // "Should we allow the user to switch between the AR and Object Preview modes?",

  // TODO: Missing - stonewall has an existing prop: allow_object_preview - not awailable in the JSON schema. Old schema

  // TODO: WebExtra - nice to have
  targetEnvironment: 'object' | 'room' | 'world'; // Depending on this setting we will define the initial camera position. For object it should be something like half meter away from the target, room - around 1.5 meters away. World - 3 meters..
}

// Todo: is this the action state?
export interface IActionState {
  uid: string; // "A unique identifier for the state.",
  type: 'object' | 'experience';
}

// A string hexadecimal representation of color. The color value may or may not be prefixed with a '#' character. The value can have 6 digits or 8 if alpha is included.",
export interface IUIElementConfiguration {
  cta_color?: HexadecimalColor; // "A string hexadecimal representation of color. The color value may or may not be prefixed with a '#' character. The value can have 6 digits or 8 if alpha is included.",
}

export interface ISoundtrack {
  uid: string; // "A unique identifier for the soundtrack",
  folder: string; // "Containing folder for this file",
  filename: string; // "The sound file to play",
  loops: boolean; // TRUE if the sound should loop, FALSE if it plays through once
  volume?: NormalizedPercentage; // "volume percentage for this sound effect. Accepts values in range [0.0, 1.0]",
}
// An optional timing function defining the pacing of an animation.
export type timingFunction =
  | 'default'
  | 'linear'
  | 'easeIn'
  | 'easeOut'
  | 'easeInEaseOut'
  | 'easeInSine'
  | 'easeOutSine'
  | 'easeInOutSine'
  | 'easeInQuad'
  | 'easeOutQuad'
  | 'easeInOutQuad'
  | 'easeInCubic'
  | 'easeOutCubic'
  | 'easeInOutCubic'
  | 'easeInQuart'
  | 'easeOutQuart'
  | 'easeInOutQuart'
  | 'easeInQuint'
  | 'easeOutQuint'
  | 'easeInOutQuint'
  | 'easeInExpo'
  | 'easeOutExpo'
  | 'easeInOutExpo'
  | 'easeInCirc'
  | 'easeOutCirc'
  | 'easeInOutCirc'
  | 'easeInBack'
  | 'easeOutBack'
  | 'easeInOutBack';

export interface IModifierValue {
  final: 'number' | 'boolean'; // "The final value of the modifier",
  initial?: 'number' | 'boolean'; // "The initial value of the modifier",
}

export interface IModifier {
  uid: string; // "A unique identifier for the action",
  node_name: string; //  "A node to perform the modification on",
  property:
    | 'colliderNodes'
    | 'isHidden'
    | 'isPaused'
    | 'opacity'
    | 'shaderFloat'
    | 'shaderFloat2'
    | 'shaderFloat3'
    | 'shaderFloat4'
    | 'playPauseShader'; // "A property to modify",
  shader_property?: string; // "Arbitrary shader property name that is defined in a shader or shader modifier.",
  values: IModifierValue; //  "A duration in seconds for which to animate the modification",
  duration?: PositiveNumber; // "A duration in seconds for which to animate the modification",
  delay?: PositiveNumber; // "An optional delay in seconds after which the modification should be applied.",
  timing_function?: timingFunction; // "An optional timing function defining the pacing of an animation.",
}

export interface IAction {
  uid: string; // "A unique identifier for the action",
  animation_key?: string; // "Key name for animation triggered",
  prompt?: string; // A prompt which should be displayed to the user upon starting this action
  loops?: number; // "A value specifying the number of repetitions the animation assigned to this action should have. If the value is negative it represents an infinite loop. Values 0 and 1 have no difference since they both mean 'play animation exactly once' as per iOS implementation",
  soundtrack?: string; // "A sound effect uid which should be played upon the action start",
  modifiers?: Array<IModifier>; // "Modifiers that can be applied to the object",
  interruptible: boolean; // "A value indicating whether the currently active action can be interrupted by triggering another action in the action chain",
  is_continuous: boolean; //  "A value indicating whether the action (if part of an action chain) is started automatically upon previous action ending or should the trigger which started the chain be applied again in order to progress through the sequence", {
  duration?: PositiveNumber; // A duration in seconds after which the action is stopped. The next action in the action chain is executed if applicable. Value can be defined as a whole number or a number with a fractional value depending on the desired precision.
  set_states?: string[]; // An array of state UIDs to set.
}

export interface IActionChain {
  uid: string; // "A unique identifier for the action chain",
  actions: string[]; // "An ordered array of action uids forming the chain",
  // TODO:  creation, state_update? Why is this an array and not a stringItemSet defined inte JSON schema
  triggered_by?: Set<'tap' | 'placement' | 'proximity_enter' | 'proximity_exit' | 'creation, state_update'>; // "The acts that triggers the animation chain. This can be on creation, on tap, on placement or a proximity event",
  trigger_nodes?: Set<string>; // "The nodes that the triggered_by action chain can act on. If empty apply to entire object"
  radius?: PositiveNumber; // "A positive value indicating a radius upon which a proximity-based action should be triggered. This value is interpreted in metric units",
  repeat_cycle_index?: PositiveInteger; // "An index of first action in chain which starts an infinite repeat cycle",

  cleanup_action_chain?: string; // "The unique identifier for the cleanup action chain",
  conditions?: string[][]; // "An array arrays of state UIDs which are to be checked based on OR boolean logic for outer arrays and on AND boolean logic for states in an inner array.",
}

export interface IExperienceMode {
  uid: string; // "A unique identifier for the mode",
  experience_type: 'front_face' | 'back_face' | 'back_place'; // "Whether an experience uses the front_face front camera, eg face filters or head attached geo, or back_place eg placing objects in world, or back_face applying face filters to friends through camera (not yet supported)",
  objects: Set<string>;
}

export interface IBlendShapeAction {
  uid: string; // "A unique identifier for the blend shape action."
  type: 'scale' | 'rotate' | 'translate'; // "A type of blend shape action.",
  // TODO: will this definition of array of number work?
  values: { 0: number; 1?: number; 2?: number }; //  "An array of at minimum one value and at most three values specifying three axis' value changes.",
  nodes: Set<string>; // "The nodes that the should be modified by the given values.",{
  inverted?: boolean; // "If true, blend shapes' coefficient will be subtracted from 1 so that the value of 0 will read out as a maximum coefficient and vice versa.",
  threshold?: PositiveNumber; // "A threshold below which blend shape's coefficient changes are ignored.",
  timing_function?: timingFunction; // "An optional timing function defining the pacing of an animation.",
  absolute_value?: boolean; // "If false, values specified are considered percentage of user's face bounding box. If true, the values are used as given in the metric scale.",
}

export interface IBlendShape {
  uid: string; // "A unique identifier for the blend shape.",
  type:
    | 'browDownLeft'
    | 'browDownRight'
    | 'browInnerUp'
    | 'browOuterUpLeft'
    | 'browOuterUpRight'
    | 'cheekPuff'
    | 'cheekSquintLeft'
    | 'cheekSquintRight'
    | 'eyeBlinkLeft'
    | 'eyeBlinkRight'
    | 'eyeLookDownLeft'
    | 'eyeLookDownRight'
    | 'eyeLookInLeft'
    | 'eyeLookInRight'
    | 'eyeLookOutLeft'
    | 'eyeLookOutRight'
    | 'eyeLookUpLeft'
    | 'eyeLookUpRight'
    | 'eyeSquintLeft'
    | 'eyeSquintRight'
    | 'eyeWideLeft'
    | 'eyeWideRight'
    | 'jawForward'
    | 'jawLeft'
    | 'jawOpen'
    | 'jawRight'
    | 'mouthClose'
    | 'mouthDimpleLeft'
    | 'mouthDimpleRight'
    | 'mouthFrownLeft'
    | 'mouthFrownRight'
    | 'mouthFunnel'
    | 'mouthLeft'
    | 'mouthLowerDownLeft'
    | 'mouthLowerDownRight'
    | 'mouthPressLeft'
    | 'mouthPressRight'
    | 'mouthPucker'
    | 'mouthRight'
    | 'mouthRollLower'
    | 'mouthRollUpper'
    | 'mouthShrugLower'
    | 'mouthShrugUpper'
    | 'mouthSmileLeft'
    | 'mouthSmileRight'
    | 'mouthStretchLeft'
    | 'mouthStretchRight'
    | 'mouthUpperUpLeft'
    | 'mouthUpperUpRight'
    | 'noseSneerLeft'
    | 'noseSneerRight'
    | 'tongueOut'; // "A type of blend shape as defined at: https://developer.apple.com/documentation/arkit/arfaceanchor/blendshapelocation.",
  threshold?: PositiveNumber; // "A threshold below which blend shape's coefficient changes are ignored.",
  duration?: PositiveNumber; // "A duration which specifies how long a change between blend shape's coefficients should be animated for.",
  timing_function?: timingFunction; // "An optional timing function defining the pacing of an animation.",
  actions?: Array<IBlendShapeAction>; //  "An array of actions to perform for a given blend shape.",
}

// TODO: json schema names it just 'object' which is an invalid name in js
export interface ISceneObject {
  uid: string; // "A unique identifier for the object",
  folder: string; // "Containing folder for this object",
  scene?: string; // "Name of the file for the object",
  blend_shapes?: Array<IBlendShape>; // "An array of blend shapes to track."
  node_name?: string; // "Top level node on the asset",
  object_title?: string; // "Product name field to display",
  object_subtitle?: string; // "Text to display below product name",
  soundtrack?: string; // "Sound effect to play with the object",
  preview_image?: string; // "A thumbnail png to show in the carousel for the object",
  // TODO: json schema seems to be messerd up with string maxLength = 0
  cta_link?: UrlString; // "Text to display below product name",
  actions?: Array<IAction>; // "Actions that can be triggered for the object",
  action_chains?: Array<IActionChain>; // "Action chains defined for the object",
  enable_highlight?: boolean; // "If true, this when an object is selected it will be highlighted and have the reticle display below it, this is the default. If false there will not be a visible change to the selected object",
  idle_action_chain?: string; // "The animation to play in an idle state, when other animations are not being played, if this is not specified the object is static",
  textures_folder?: string; // "Containing folder for textures", // Needed fro .scn files. Unused for gltf
  // TODO: Needed fro .scn files. Unused for glt.f However might be needed for future implementation of face filters
  textures?: Set<string>; // "All files needed for download or textures applied to face"

  // TODO: defined as array in json schema
  // TODO: no plane definition on webAR
  planes?: Set<'horizontal' | 'vertical'>; // "Whether this object can be placed on horizontal, vertical or both planes",
  maximum_instances?: PositiveNumber; // "A maximum number of object instances that can be created on scene. If this property is absent, there is no instance limit for this particular object",

  // TODO: extra values for json
  webExtra?: {
    scale?: number;
    position?: number[];
  };
}

export interface IExperienceData {
  uid: string; // "A unique identifier for the experience",
  root_folder: UrlString; // "A folder where all of the experience's asset paths are calculated from",
  marquee_asset?: string; // "The uid for the object that can be loaded before the rest of the assets for use in displaying in the pre-tap view for entry into the AR experience",
  preview_thumbnail?: string; // "A filename for the thumbnail preview image that can be displayed in the pre-tap view for entry into the AR experience",
  marquee_prompt: string; // "Text to display in the view for entry into the AR experience",
  placement_prompt: string; // "Initial prompt text to encourage placement of an object",
  allow_multiple?: boolean; //  "If True, selecting an item in the carousel adds a new object to the scene. If False selecting another item in the carousel would replace the current item rather than adding a new instance",
  cta_text: string; // "Text to display on call to action button",
  cta_link_general?: UrlString; // "A link to open upon tapping a call to action button",
  allow_scaling?: boolean; // "Allow scaling of placed objects, this is set to FALSE for world scale objects",
  share_text?: string; // "Text that accompanies photo or video on social sharing platform",
  version: string; // "A semantic version string representing our JSON schema version". "pattern": "\\A(\\d+\\.\\d+\\.\\d+)(-([0-9A-Za-z-]+(\\.[0-9A-Za-z-]+)*))?(\\+([0-9A-Za-z-]+(\\.[0-9A-Za-z-]+)*))?\\Z"
  platform: 'ios' | 'android'; // "Whether this is an iOS or Android scheme",
  hdr_images?: Set<string>; // "Files used for image based lighting and referenced in the lighting options by the objects",
  logo?: UrlString; // "Location of logo file, if not provided we don't render logo elements",
  sounds?: ISoundtrack[]; // "All the sounds in the experience including background and object associated sounds",
  soundtrack?: string; // The id of the sound object to play throughout the entire experience",
  modes: Array<IExperienceMode>; // "Definition of experience with specified camera direction",
  objects: Array<ISceneObject>; //  "An arbitrary number of objects can be included",
  ar_session_configuration?: IArSessionConfiguration; // "A list of flags used to configure AR session, lighting and other parameters.",
  ui_element_configuration?: IUIElementConfiguration; // "A dictionary of UI-related properties",
}

export class SceneObject {
  public readonly data: ISceneObject;

  private readonly experience: ExperienceData;

  constructor(data: ISceneObject, experience: ExperienceData) {
    this.data = data;
    this.experience = experience;
  }

  get uid() {
    return this.data.uid;
  }

  get previewImage() {
    // Otherwise check if the experience has it's own preview object
    if (this.data.preview_image) {
      return `${this.experience.rootFolder}/${this.data.folder}/${this.data.preview_image}`;
    }
    // lastly just return some default image
    return '/img/ar-logo.png';
  }

  get scene() {
    // Otherwise check if the experience has it's own preview object
    if (this.data.scene) {
      return `${this.experience.rootFolder}/${this.data.folder}/${this.data.scene}`;
    }
    throw new Error('SceneObject contains no scene');
  }

  get soundtrack() {
    if (this.data.soundtrack) {
      if (!this.experience.data.sounds || !this.experience.data.sounds.find(s => s.uid === this.data.soundtrack)) {
        return null;
      }
      const sound = this.experience.data.sounds.find(s => s.uid === this.data.soundtrack);
      return sound;
    }
    return null;
  }

  get folder() {
    return `${this.experience.rootFolder}/${this.data.folder}/`;
  }
}

export class ExperienceData {
  public readonly data: IExperienceData;

  public readonly sceneObjects: SceneObject[];

  constructor(data: IExperienceData) {
    this.data = data;

    // Currently in webAR we onkly support sceneModels which as 3d scenes in them. (iOS and Android supports just texture mapping an image on the face)
    this.sceneObjects = data.objects.filter(o => o.scene !== undefined).map(o => new SceneObject(o, this));
  }

  get configuration(): IArSessionConfiguration {
    return (
      this.data.ar_session_configuration || {
        targetEnvironment: 'room',
      }
    );
  }

  get targetEnvironment() {
    if (this.data.ar_session_configuration) {
      return this.data.ar_session_configuration?.targetEnvironment;
    }
    return 'room';
  }

  get experience_type() {
    return this.data.modes[0].experience_type;
  }

  get uid() {
    return this.data.uid;
  }

  get cta_text() {
    return this.data.cta_text;
  }

  get rootFolder() {
    return this.data.root_folder;
  }

  get soundtrack() {
    if (this.data.soundtrack) {
      if (!this.data.sounds || !this.data.sounds.find(s => s.uid === this.data.soundtrack)) {
        return null;
      }
      const sound = this.data.sounds.find(s => s.uid === this.data.soundtrack);
      return sound;
    }
    return null;
  }

  get previewAsset() {
    // first check if preview object is one of the objects of the experience
    if (this.data.marquee_asset) {
      const object = this.data.objects.find(o => o.uid === this.data.marquee_asset);
      if (object && object.preview_image) {
        return `${this.data.root_folder}/${object.folder}/${object.preview_image}`;
      }
    }

    // Otherwise check if the experience has it's own preview object
    if (this.data.preview_thumbnail) {
      return `${this.data.root_folder}/${this.data.preview_thumbnail}`;
    }

    // lastly just return some default image
    return '/img/ar-logo.png';
  }
}
